<template>
  <iframe src="https://nagenda.online/studiogiovannaevelin" frameborder="0"></iframe>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
iframe{
  width: 100%;
  height: 99vh;
}
</style>
